import ReactMarkdown from 'react-markdown';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 85px 0 100px;
  background-color: ${Colors.gin};

  @media (max-width: ${tablet}) {
    padding: 70px 0 40px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${tablet}) {
    flex-direction: column-reverse;
  }
`;

const Media = styled.div`
  width: 50%;
  flex-shrink: 0;

  @media (max-width: ${tablet}) {
    width: 100%;
    max-width: 560px;
    margin-top: 35px;
  }
`;

const Info = styled.div`
  flex-basis: 100%;
  padding-left: 90px;
  padding-right: 60px;

  @media (max-width: ${tablet}) {
    padding: 0;
    text-align: center;
  }
`;

const Text = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 50px;
`;

export {
  Wrapper,
  Content,
  Media,
  Info,
  Text,
}