import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Layouts
import Container from '../../layouts/Container';
import {
  Wrapper,
  Head1,
  QualityRow,
  QualityItem,
  ImageWrap,
  Head4,
  Text,
} from './styled';

const Quality = ({
  data: {
    title,
    qualityItems,
  },
}) => {
  return (
    <Container>
      <Wrapper>
        <Head1 value={title} />
        <QualityRow>
          {qualityItems.map((item) => {
            const { title, text, id, icon } = item;
            return (
              <QualityItem key={id}>
                <ImageWrap>
                  <GatsbyImage image={icon.localFile?.childImageSharp?.gatsbyImageData} alt={icon.name} />
                </ImageWrap>
                <Head4 value={title} />
                <Text>{text}</Text>
              </QualityItem>
            );
          })}
        </QualityRow>
      </Wrapper>
    </Container>
  );
};

Quality.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Quality;
