import React from 'react';
import PropTypes from 'prop-types';
// Layouts
import Container from '../../layouts/Container';
// Components
import CenterBanner from '../../templates/CenterBanner';
// UI
import Faq from '../../UI/Faq';
import {
  Wrapper,
  Head2,
  FaqWrap,
} from './styled';

const FaqBanner = ({
  data: {
    faqSection: {
      title,
      faqItems,
    },
    shopNowBanner,
  },
}) => {
  return (
    <Wrapper>
      <Container>
        <Head2 value={title} />
        <FaqWrap>
          <Faq data={faqItems} />
        </FaqWrap>
      </Container>
      <CenterBanner data={shopNowBanner} />
    </Wrapper>
  );
};
 
FaqBanner.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default FaqBanner;
