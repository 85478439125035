import BackgroundImage from 'gatsby-background-image';
//UI
import H4 from '../../UI/Titles/H4';
import Button from '../../UI/Button';
// Styled
import styled from 'styled-components';
import Fonts from '../../../assets/styles/Fonts';
import Colors from '../../../assets/styles/Colors';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled(BackgroundImage)`
  padding: 185px 0 145px;
  background-size: cover;
  background-position: center;
  background-color: var(--lightgreen);

  @media (max-width: ${tablet}) {
    padding: 140px 0 50px;
  }
`;

const Content = styled.div`
  display: flex;

  @media (max-width: ${tablet}) {
    display: block;
  }
`;

const Info = styled.div`
  flex-basis: 100%;
  padding-right: 70px;

  @media (max-width: ${tablet}) {
    padding: 0;
    text-align: center;
  }
`;

const Head4 = styled(H4)`
  margin-top: 40px;
  text-decoration: underline;
  font-family: ${Fonts.IBMSemiBold};
`;

const ContactRow = styled.div`
    display: flex;
    flex-direction: column;
    

    margin: 1.5vh 0;

    @media (max-width: ${tablet}) {
        align-items: center;
    }
`;

const ContactLink = styled.a`
    display: flex;
    align-items: center;

    text-decoration: underline;
    color: inherit;

    margin-top: 0.5vh;
    margin-right: 50px;

    p{
        margin-left: 5px;
    }

    @media (max-width: ${tablet}) {
        margin-right: 0;
    }
`;

const Schedule = styled.div`
  margin-top: 40px;
  color: ${Colors.green};
  font-size: 18px;
  line-height: 27px;

  @media (max-width: ${tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ScheduleRow = styled.div`
  display: flex;
  align-items: center;
`;

const ScheduleDay = styled.div`
  min-width: 135px;
  flex-shrink: 0;
  padding-right: 35px;
  text-align: right;
  text-transform: uppercase;
  font-family: ${Fonts.IBMRegular};
`;

const ScheduleTime = styled.div`
  flex-shrink: 0;
  font-family: ${Fonts.IBMMedium};
`;

const Btn = styled(Button)`
  max-width: 270px;
  width: 100%;
  margin-top: 40px;
`;

const Media = styled.div`
  width: 50%;
  flex-shrink: 0;

  @media (max-width: ${tablet}) {
    max-width: 560px;
    width: 100%;
    margin: 40px auto 0;
  }
`;

export {
  Wrapper,
  Content,
  Info,
  Head4,
  ContactRow,
  ContactLink,
  Schedule,
  ScheduleRow,
  ScheduleDay,
  ScheduleTime,
  Btn,
  Media,
}