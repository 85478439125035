import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Layouts
import Container from '../../layouts/Container';
// UI
import H2 from '../../UI/Titles/H2';
import {
  Wrapper,
  Content,
  Media,
  Info,
  Text,
} from './styled';

const LocationInfo = ({
  data: {
    title,
    text,
    image,
  }
}) => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Media>
            <GatsbyImage image={image.localFile?.childImageSharp?.gatsbyImageData} alt={image.name} />
          </Media>
          <Info>
            <H2 value={title} />
            <Text>{text}</Text>
          </Info>
        </Content>
      </Container>
    </Wrapper>
  );
};

LocationInfo.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default LocationInfo;
