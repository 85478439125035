import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Layouts
import Container from '../../layouts/Container';
import {
  Wrapper,
  Head1,
  Head4,
  Map,
  Btn,
} from './styled';

const LocationMap = ({
  data: {
    title,
    subtitle,
    map,
    button,
  },
}) => {
  return (
    <Container>
      <Wrapper>
        <Head1 value={title} />
        <Head4 value={subtitle} />
        <Map>
          <GatsbyImage image={map.localFile?.childImageSharp?.gatsbyImageData} alt={map.name} />
        </Map>
        {/* <Btn value={button.text} href={button.href} sm="true" /> */}
      </Wrapper>
    </Container>
  );
};

LocationMap.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default LocationMap;
