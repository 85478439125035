// UI
import H2 from '../../UI/Titles/H2';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 80px 0 0;
  background-color: ${Colors.gin};

  @media (max-width: ${tablet}) {
    padding: 100px 0 0;
  }
`;

const Head2 = styled(H2)`
  max-width: 1050px;
  margin: 0 auto 50px;
  text-align: center;
  color: ${Colors.green};

  @media (max-width: ${tablet}) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const FaqWrap = styled.div`
  padding-bottom: 70px;

  @media (max-width: ${tablet}) {
    padding-bottom: 50px;
  }
`;

export {
  Wrapper,
  Head2,
  FaqWrap,
}