import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
// Layout
import MainLayout from '../components/layouts/MainLayout';
// Components
import Seo from "../components/templates/Seo/seo";
import LocationBanner from '../components/templates/LocationBanner';
import LocationInfo from '../components/templates/LocationInfo';
import LocationMap from '../components/templates/LocationMap';
import Quality from '../components/templates/Quality';
import Links from '../components/templates/Links';
import CategoriesCard from '../components/templates/CategoriesCard';
import FaqBanner from '../components/templates/FaqBanner';
// Styled
import styled from 'styled-components';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const WrapperBg = styled(BackgroundImage)`
  padding-bottom: 70px;
  background-size: cover;
  background-position: center;
`;

const ContactPage = () => {
  const {
    seo,
    schedule,
    locationInformation,
    map,
    quality,
    links,
    categories,
    faqSection,
    shopNowBanner,
  } = ContactQuery();

  const imageData = getImage(map.background?.localFile?.childImageSharp?.gatsbyImageData)
  const bgimage = convertToBgImage(imageData);

  const SEOSchema = {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://torontocannabisauthority.ca/#website","url":"https://torontocannabisauthority.ca/","name":"TORONTO CANNABIS AUTHORITY","description":"We\u2019ve Got The Best Weed in Toronto!","potentialAction":[{"@type":"SearchAction","target":"https://torontocannabisauthority.ca/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"en-US"},{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/contact/#webpage","url":"https://torontocannabisauthority.ca/contact/","name":"Contact - TORONTO CANNABIS AUTHORITY","isPartOf":{"@id":"https://torontocannabisauthority.ca/#website"},"datePublished":"2021-05-14T09:26:02+00:00","dateModified":"2021-05-14T09:26:02+00:00","breadcrumb":{"@id":"https://torontocannabisauthority.ca/contact/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://torontocannabisauthority.ca/contact/"]}]},{"@type":"BreadcrumbList","@id":"https://torontocannabisauthority.ca/contact/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"item":{"@type":"WebPage","@id":"https://torontocannabisauthority.ca/","url":"https://torontocannabisauthority.ca/","name":"Home"}},{"@type":"ListItem","position":2,"item":{"@id":"https://torontocannabisauthority.ca/contact/#webpage"}}]}]}


  return (
    <MainLayout>
        <Seo
          title={seo?.metaTitle || 'Contact Page - Toronto Cannabis Authority'}
          description={seo?.metaDescription || 'Toronto Cannabis Authority Page'}
          keywords={seo?.keywords || 'Toronto Cannabis Authority'}
          schemaMarkup={SEOSchema}
        />
      <LocationBanner data={schedule} />
      <LocationInfo data={locationInformation} />
      <WrapperBg
        Tag="section"
        {...bgimage}
      >
        <LocationMap data={map} />
        <Quality data={quality} />
        <Links data={links} />
      </WrapperBg>
      <CategoriesCard data={categories} />
      <FaqBanner data={{ faqSection, shopNowBanner }} />
    </MainLayout>
  );
};

export default ContactPage;

const ContactQuery = () => {
  const data = useStaticQuery(
    graphql`
      query ContactQuery {
        strapiLocationPage {
          schedule {
            title
            subtitle
            scheduleItems {
              id
              day
              time
            }
            image {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            button {
              href
              text
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
          locationInformation {
            title
            text
            image {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          map {
            title
            subtitle
            button {
              href
              text
            }
            map {
              name
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
          quality {
            title
            qualityItems {
              title
              text
              id
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
                name
              }
            }
          }
          links {
            id
            link
            subtitle
            title
          }
          categories {
            title
            category {
              id
              name
              image {
                name
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                    
                  }
                }
              }
              link
            }
            bottomSubtitle
          }
          faqSection {
            title
            faqItems {
              answer
              question
              id
            }
          }
          shopNowBanner {
            title
            description
            button {
              href
              text
            }
            background {
              localFile {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    `
  );

  const {
    strapiLocationPage: {
      schedule,
      locationInformation,
      map,
      quality,
      links,
      categories,
      faqSection,
      shopNowBanner,
    },
  } = data;

  return {
    schedule,
    locationInformation,
    map,
    quality,
    links,
    categories,
    faqSection,
    shopNowBanner,
  };
}
