import ReactMarkdown from 'react-markdown';
// UI
import H1 from '../../UI/Titles/H1';
import H4 from '../../UI/Titles/H4';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 70px 0 50px;
  text-align: center;

  @media (max-width: ${tablet}) {
    padding: 55px 0 20px;
  }
`;

const Head1 = styled(H1)`
  margin: 0 auto;
  max-width: 1150px;
  color: ${Colors.aqua};

  @media (max-width: ${tablet}) {
    font-size: 25px;
    line-height: 28px;
  }
`;

const QualityRow = styled.div`
  margin: 100px -12.5px 0;
  display: flex;

  @media (max-width: ${tablet}) {
    display: block;
    margin: 35px 0 0;
  }
`;

const ImageWrap = styled.div`
  width: 120px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: ${tablet}) {
    width: 100%;
  }
`;

const QualityItem = styled.div`
  width: calc(33.33% - 25px);
  margin: 0 12.5px;
  padding: 65px 35px 55px;
  color: ${Colors.aqua};
  text-align: center;
  border: 3px solid ${Colors.green};
  border-radius: 25px;

  img {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: ${tablet}) {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 15px;
    padding: 65px 30px 35px;
  }
`;

const Head4 = styled(H4)`
  margin-top: 25px;
`;

const Text = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 40px;
  font-size: 16px;
  line-height: 19px;
`;

export {
  Wrapper,
  Head1,
  QualityRow,
  QualityItem,
  ImageWrap,
  Head4,
  Text,
}