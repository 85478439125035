// UI
import H1 from '../../UI/Titles/H1';
import H4 from '../../UI/Titles/H4';
import Button from '../../UI/Button';
// Styled
import styled from 'styled-components';
import Fonts from '../../../assets/styles/Fonts';
import { tablet } from "../../../assets/styles/Responsive";

const Wrapper = styled.div`
  padding: 80px 0 70px;
  text-align: center;

  @media (max-width: ${tablet}) {
    padding: 50px 0 55px;
  }
`;

const Head1 = styled(H1)`
  margin: 0 auto;
  max-width: 1150px;
`;

const Head4 = styled(H4)`
  margin: 50px auto 0;
  max-width: 310px;
  font-family: ${Fonts.IBMRegular};
  text-decoration: underline;

  @media (max-width: ${tablet}) {
    margin-top: 40px;
  }
`;

const Map = styled.div`
  max-width: 1150px;
  width: 100%;
  margin: 55px auto 0;
  border-radius: 25px;
  overflow: hidden;

  img {
    display: block;
  }

  @media (max-width: ${tablet}) {
    margin: 40px auto 0;
    width: 100%;
    max-width: 560px;

    img {
      height: 260px;
    }
  }
`;

const Btn = styled(Button)`
  max-width: 365px;
  width: 100%;
  margin-top: 55px;

  @media (max-width: ${tablet}) {
    margin-top: 40px;
  }
`;

export {
  Wrapper,
  Head1,
  Head4,
  Map,
  Btn,
}