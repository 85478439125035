import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Layouts
import Container from '../../layouts/Container';
// UI
import H2 from '../../UI/Titles/H2';
import {
  Wrapper,
  Content,
  Info,
  Head4,
  ContactRow,
  ContactLink,
  Schedule,
  ScheduleRow,
  ScheduleDay,
  ScheduleTime,
  Btn,
  Media,
} from './styled';

//Icons For Contact
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const LocationBanner = ({
  data: {
    title,
    subtitle,
    scheduleItems,
    image,
    button,
    background,
  },
  className,
}) => {

    const imageData = getImage(background?.localFile?.childImageSharp?.gatsbyImageData)
    const bgimage = convertToBgImage(imageData);

  return (
    <Wrapper
      Tag="section" 
      className={className}
      {...bgimage}
    >
      <Container leftPadding="true">
        <Content>
          <Info>
            <H2 value={title} />
            <Head4 value={subtitle} />
            <ContactRow>

                <ContactLink
                    href="tel:647-557-7444"
                >
                    
                    <p>
                    647-557-7444
                    </p>
                </ContactLink>

                <ContactLink
                    href="mailto:info@torontocannabisauthority.ca"
                >
                    
                    <p>
                      info@torontocannabisauthority.ca
                    </p>
                </ContactLink>
            </ContactRow>
            <Schedule>
              {scheduleItems.map((item) => {
                const { id, day, time } = item;
                return (
                  <ScheduleRow key={id}>
                    <ScheduleDay>{day}</ScheduleDay>
                    <ScheduleTime>{time}</ScheduleTime>
                  </ScheduleRow>
                );
              })}
            </Schedule>
            <Btn value={button.text} href={button.href} sm="true" />
          </Info>
          <Media>
            <GatsbyImage image={image.localFile?.childImageSharp?.gatsbyImageData} alt={image.name} />
          </Media>
        </Content>
      </Container>
    </Wrapper>
  );
};

LocationBanner.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default LocationBanner;
